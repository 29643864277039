import IKDataEntity from '@/plugins/EntityFrameWork/IKDataEntity'
import { hillo } from 'hillo'

const entity = {
  orderId: {},
  date: {},
  progressivoInvio: {},
  pivaPaese: {},
  pive: {},
  denominazione: {},
  cap: {},
  pecSend: {},
  sendEmail: {
    type: IKDataEntity.Types.Integer,
    form: false,
    tableConfig: {
      overwrite: true
    }
  }
}

const config = {
  load: async function (filter) {
    return (await hillo.get('FatturaPAController.php?op=getFPAList', {})).content
  }
}

export default IKDataEntity.ModelFactory(entity, config)
